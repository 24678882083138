import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'objectFieldsEmpty',
})
export class ObjectFieldsEmptyPipe implements PipeTransform {
  transform(value: {[key: string]: any} | null): boolean {
    if (!value) return true;

    return Object.values(value).every(
      value => value === undefined || value === null || value === '' || value?.length < 1,
    );
  }
}
