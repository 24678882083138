import {Provider} from '@angular/core';
import {TUI_ICONS_PATH} from '@taiga-ui/core';

export const CUSTOM_ICONS_PROVIDER: Provider = {
  provide: TUI_ICONS_PATH,
  useValue: iconsCustomPath,
};

function iconsCustomPath(name: string): string {
  if (name.startsWith('tuiIcon') || name.startsWith('pikIcon')) {
    return `assets/taiga-ui/icons/${name}.svg#${name}`;
  }

  return name;
}
