import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';

import {EnableFullscreen, ExitFullscreen} from './fullscreen.actions';

export interface FullscreenStateModel {
  enabled: boolean;
}

const defaults: FullscreenStateModel = {
  enabled: false,
};

export const FULLSCREEN_STATE_NAME = 'fullscreen';
export const FULLSCREEN_STATE_TOKEN = new StateToken<FullscreenStateModel>(
  FULLSCREEN_STATE_NAME,
);

@State({
  name: FULLSCREEN_STATE_TOKEN,
  defaults,
})
@Injectable()
export class FullscreenState {
  @Action(EnableFullscreen)
  enable({patchState}: StateContext<FullscreenStateModel>): void {
    patchState({
      enabled: true,
    });
  }

  @Action(ExitFullscreen)
  exit({patchState}: StateContext<FullscreenStateModel>): void {
    patchState({
      enabled: false,
    });
  }

  @Selector()
  static fullscreenMode(state: FullscreenStateModel): boolean {
    return state.enabled;
  }
}
