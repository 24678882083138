import {ChangeDetectionStrategy, Component, HostListener} from '@angular/core';
import {EnableFullscreen, ExitFullscreen} from '@app/core/store/fullscreen.actions';
import {Store} from '@ngxs/store';

type DocumentEventLike<T> = Event & {
  currentTarget: T;
};

@Component({
  selector: 'sgp-fullscreen-watcher',
  template: '',
  styleUrls: ['./fullscreen-watcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullscreenWatcherComponent {
  constructor(private readonly store: Store) {}

  @HostListener('document:fullscreenchange', ['$event']) fullscreenchange(
    $event: DocumentEventLike<{
      fullscreenElement: Document | null;
    }>,
  ) {
    if ($event.currentTarget.fullscreenElement) {
      this.store.dispatch(new EnableFullscreen());
    } else {
      this.store.dispatch(new ExitFullscreen());
    }
  }
}
