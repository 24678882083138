import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[sgpDbClickPrevent]',
})
export class DbClickPreventDirective {
  @HostListener('dblclick', ['$event']) dblclick(event: Event) {
    event.stopPropagation();
  }
}
