import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

function inputIsNotNull<T>(input: null | T): input is T {
  return input !== null;
}

export function isNotNull<T>() {
  return (source$: Observable<null | T>) => source$.pipe(filter(inputIsNotNull));
}
