import {Directive, Input, HostListener} from '@angular/core';
import {YandexMetricService} from '../services/yandex-metric.service';

@Directive({
  selector: '[sgpYmGoal]',
})
export class YandexMetrikaGoalDirective {
  @Input() trackOn: string;

  constructor(private readonly ym: YandexMetricService) {}

  @HostListener('click') click() {
    this.ym.reachGoal.next(this.trackOn);
  }
}
