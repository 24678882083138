import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PikAuthService} from 'pik-header';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';

@Injectable()
export class UpdateTokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;

  /**
   * Переменная для отслеживания процесса обновления токена
   *
   * @private
   * @type {BehaviorSubject<boolean>}
   * @memberof PikAuthInterceptor
   */
  private readonly tokenRefreshed: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(private readonly auth: PikAuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(this.addAuthenticationToken(request)).pipe(
      catchError(error => {
        if (error.status !== 401) {
          // eslint-disable-next-line no-console
          console.debug('[PikAuthInterceptor] Not an 401 error');

          return throwError(error);
        }

        if (this.auth.isTokenExpired()) {
          // eslint-disable-next-line no-console
          console.debug('[PikAuthInterceptor] Token was expired');
        }

        if (this.refreshTokenInProgress) {
          // eslint-disable-next-line no-console
          console.debug('[PikAuthInterceptor] Refreshing of token is in progress');
          // если токен был обновлён - отправляем запрос снова

          return this.tokenRefreshed.pipe(
            filter(res => res !== null),
            take(1),
            switchMap(() => {
              // eslint-disable-next-line no-console
              console.debug(
                '[PikAuthInterceptor] Send original http request cause other refresh request complete',
              );

              return next.handle(this.addAuthenticationToken(request));
            }),
          );
        }

        // eslint-disable-next-line no-console
        console.debug('[PikAuthInterceptor] Force Refresh Token');

        // токен начали обновлять
        this.refreshTokenInProgress = true;
        // отслеживаем обновление токена
        this.tokenRefreshed.next(false);

        // выполняем обновление сессии
        return this.auth.forceRefreshSession().pipe(
          take(1),
          switchMap(() => {
            // eslint-disable-next-line no-console
            console.debug('[PikAuthInterceptor] New token has been received');
            // отмечаем, что токен больше не обновляем
            this.refreshTokenInProgress = false;
            // указываем, что токен обновился
            this.tokenRefreshed.next(true);
            // выполняем исходный запрос
            // eslint-disable-next-line no-console
            console.debug('[PikAuthInterceptor] Send original http request');

            return next.handle(this.addAuthenticationToken(request));
          }),
          catchError(err => {
            // eslint-disable-next-line no-console
            console.debug('[PikAuthInterceptor] Error during force refresh token');

            // отмечаем, что токен больше не обновляем
            this.refreshTokenInProgress = false;
            // пробрасываем ошибку выше по стеку
            throw err;
          }),
        );
      }),
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const accessToken = this.auth.getToken();

    if (!accessToken) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: this.auth.getAuthorizationHeaderValue(),
      },
    });
  }
}
