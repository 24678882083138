import {PikAuthConfig, PikEnvironment} from 'pik-header';

export const environment = {
  production: false,
  api: 'https://api.plan.test-special.test.development.pik-digital.ru/v1.0',
  apiV1: 'https://api.plan.test-special.test.development.pik-digital.ru/v1',
  apiBackoffice:
    'https://api.plan.test-special.test.development.pik-digital.ru/backoffice/v1',
  gaId: 'G-4G4PT8HTCF',
  gtmId: '',
  yaEnabled: true,
  pikEnvironment: PikEnvironment.test,
  auth: {
    clientId: 'sgp_spa',
    scope:
      'openid profile email home_api sgp_api bop_api mds_api wiki_api navigation_api photo_api banksync_api',
  } as PikAuthConfig,
};
