import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import localeRU from '@angular/common/locales/ru';
import {NgModule} from '@angular/core';
import {FullscreenState} from '@app/core/store/fullscreen.state';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxsModule} from '@ngxs/store';
import {NgxsDispatchPluginModule} from '@ngxs-labs/dispatch-decorator';
import {TuiLetModule} from '@taiga-ui/cdk';
import {NgxsResetPluginModule} from 'ngxs-reset-plugin';
import {
  PikAuthModule,
  PikCommonModule,
  PikErrorPagesModule,
  PikHeaderModule,
} from 'pik-header';
import {environment} from 'src/environments/environment';

import {AuthCallbackComponent} from './components/auth-callback/auth-callback.component';
import {FullscreenWatcherComponent} from './components/fullscreen-watcher/fullscreen-watcher.component';
import {ApplyTokenInterceptor} from './interceptors/apply-token.interceptor';
import {ErrorsNotificationInterceptor} from './interceptors/errors-notification.interceptor';
import {ResolveApiInterceptor} from './interceptors/resolve-api.interceptor';
import {UpdateTokenInterceptor} from './interceptors/update-token.interceptor';
import {ServicesModule} from './services/services.module';
import {CUSTOM_ICONS_PROVIDER} from './providers/custom-icons.providers';

registerLocaleData(localeRU);

@NgModule({
  declarations: [AuthCallbackComponent, FullscreenWatcherComponent],
  imports: [
    NgxsModule.forRoot([FullscreenState], {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    ServicesModule,
    PikCommonModule.forRoot({
      environment: environment.pikEnvironment,
    }),
    PikAuthModule.forRoot(environment.auth),
    PikHeaderModule.forRoot(),
    PikErrorPagesModule,
    TuiLetModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ApplyTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UpdateTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ResolveApiInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorsNotificationInterceptor, multi: true},
    CUSTOM_ICONS_PROVIDER,
  ],
  exports: [
    PikHeaderModule,
    PikErrorPagesModule,
    TuiLetModule,
    FullscreenWatcherComponent,
  ],
})
export class CoreModule {}
