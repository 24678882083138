import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {TuiNotification, TuiAlertService} from '@taiga-ui/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ErrorsNotificationInterceptor implements HttpInterceptor {
  constructor(
    @Inject(TuiAlertService)
    private readonly alertService: TuiAlertService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg: string | null = error.error?.message || error.error?.error;

        if (error.status === 401) {
          return throwError(error);
        }

        if (!errorMsg) {
          errorMsg = 'Произошла ошибка при отправке запроса на сервер';
        }

        this.showErrorNotification(errorMsg, error.status);

        return throwError({
          ...error,
          error: {
            ...error.error,
            error: errorMsg,
          },
        });
      }),
    );
  }

  showErrorNotification(message: string, code: number, duration = 3000): void {
    this.alertService
      .open(message, {
        label: `Ошибка: ${code}`,
        status: 'error',
        autoClose: duration,
      })
      .subscribe();
  }
}
