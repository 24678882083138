import {AnimationOptions} from '@angular/animations';
import {ChangeDetectionStrategy, Component, HostBinding, Inject} from '@angular/core';
import {SidebarOptions} from '@app/modules/ui/sidebar/sidebar-options';
import {TuiDialog} from '@taiga-ui/cdk';
import {TUI_ANIMATION_OPTIONS} from '@taiga-ui/core';
import {tuiFadeIn, tuiSlideInRight} from '@taiga-ui/core/animations';
import {POLYMORPHEUS_CONTEXT} from '@tinkoff/ng-polymorpheus';
import {Observable} from 'rxjs';
import {TUI_DIALOG_PROVIDERS, TUI_DIALOG_CLOSE_STREAM} from './sidebar.providers';

@Component({
  selector: 'sgp-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [tuiFadeIn, tuiSlideInRight],
  providers: TUI_DIALOG_PROVIDERS,
})
export class SidebarComponent<O, I> {
  @HostBinding('@tuiSlideInRight')
  @HostBinding('@tuiFadeIn')
  readonly animation = {value: '', ...this.animationsOptions} as const;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialog<SidebarOptions<I>, O>,
    @Inject(TUI_ANIMATION_OPTIONS) private readonly animationsOptions: AnimationOptions,
    @Inject(TUI_DIALOG_CLOSE_STREAM)
    close$: Observable<unknown>,
  ) {
    close$.subscribe(() => {
      this.close();
    });
  }

  onClick(response: O) {
    this.context.completeWith(response);
  }

  close() {
    this.context.completeWith(false as any);
  }
}
