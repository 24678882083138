import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PikAuthService} from 'pik-header';
import {Observable} from 'rxjs';

@Injectable()
export class ApplyTokenInterceptor implements HttpInterceptor {
  constructor(private readonly auth: PikAuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getAuthorizationHeaderValue();

    if (token) {
      const authorizationHeaders: HttpHeaders = request.headers.set(
        'Authorization',
        token,
      );

      const nextReq = request.clone({
        headers: authorizationHeaders,
      });

      return next.handle(nextReq);
    }

    return next.handle(request);
  }
}
