<tui-scrollbar class="sidebar">
  <div class="sidebar__content">
    <h2 *ngIf="context.heading">{{ context.heading }}</h2>
    <section
      *polymorpheusOutlet="context.content; context: context">
    </section>
    <p *ngIf="context.buttons" class="buttons">
      <button
        tuiButton
        appearance="secondary"
        size="m"
        class="buttons__cancel"
        (click)="context.$implicit.complete()"
      >
        {{ context.buttons[0] }}
      </button>
      <button tuiButton size="m" (click)="context.$implicit.complete()">
        {{ context.buttons[1] }}
      </button>
    </p>
  </div>
</tui-scrollbar>
