import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PikAuthGuard} from 'pik-header';

import {NotFoundComponent} from './modules/not-found/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: async () =>
      (await import('./projects-list/projects-list.module')).ProjectsListModuleModule,
    canActivate: [PikAuthGuard],
  },
  {
    path: ':projectId',
    loadChildren: async () => (await import('./project/project.module')).ProjectModule,
    canActivate: [PikAuthGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
