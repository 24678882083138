import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {EMPTY, Observable} from 'rxjs';

type EnvironmentKey = keyof typeof environment;

@Injectable()
export class ResolveApiInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const templateVars = req.url.match(/\{(\w+)\}/g);
    let newRequest: Observable<HttpEvent<any>> | null = null;

    if (Array.isArray(templateVars)) {
      const resolvedVars = resolveVars(templateVars);

      if (!!resolvedVars) {
        newRequest = next.handle(
          req.clone({
            url: req.url.replace(
              new RegExp(templateVars.join('|'), 'g'),
              temp => resolvedVars[temp],
            ),
          }),
        );
      } else {
        newRequest = EMPTY;
        console.warn(
          req.url,
          'Ошибка в адресе запроса: не все параметры указаны в environment',
        );
      }
    }

    return !!newRequest ? newRequest : next.handle(req);
  }
}

function resolveVars(vars: string[]): {[key: string]: string} | null {
  const values: {[key: string]: string} = {};
  let valuesCount = 0;

  vars.forEach(varName => {
    const clearedVarName = varName.replace(/[\{, \}]/g, '');

    if (Object.keys(environment).includes(clearedVarName)) {
      const propertyName = clearedVarName as EnvironmentKey;
      const propertyValue = environment[propertyName];

      if (typeof propertyValue === 'string') {
        values[varName] = propertyValue;
        valuesCount += 1;
      }
    }
  });

  return vars.length === valuesCount ? values : null;
}
