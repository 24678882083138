<div class="search-bar">
  <tui-input
    tuiTextfieldIconLeft="tuiIconSearch"
    [formControl]="dynamicFormInput"
    [tuiTextfieldCleaner]="true"
    [tuiTextfieldSize]="'m'"
    [tuiTextfieldLabelOutside]="true"
  >
    {{ placeholder ? placeholder : 'Поиск проекта по ключевому слову' }}
  </tui-input>
</div>

<div automation-id="toolbar" class="tools">
  <ng-content></ng-content>
</div>
