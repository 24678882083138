import {DOCUMENT} from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appOutsideClick]',
})
export class OutsideClickDirective {
  @Input() ignoreAside = true;

  @Output() outsideClick = new EventEmitter<never>();

  constructor(
    private readonly element: ElementRef,
    @Inject(DOCUMENT) readonly document: Document,
  ) {}

  @HostListener('document:click', ['$event'])
  onGlobalClick(event: PointerEvent): void {
    let tuiSidebars = Array.from(this.document.getElementsByTagName('aside')) ?? [];

    tuiSidebars = tuiSidebars.filter(element => element.hasAttribute('tuisidebar'));

    if (this.ignoreAside && tuiSidebars.length) return;

    if (!event.composedPath().includes(this.element.nativeElement)) {
      this.outsideClick.emit();
    }
  }
}
