import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TuiButtonModule, TuiScrollbarModule} from '@taiga-ui/core';
import {PolymorpheusModule} from '@tinkoff/ng-polymorpheus';

import {SidebarComponent} from './sidebar.component';

@NgModule({
  declarations: [SidebarComponent],
  imports: [CommonModule, TuiButtonModule, PolymorpheusModule, TuiScrollbarModule],
  exports: [SidebarComponent],
})
export class SidebarModule {}
