export enum ActionsType {
  ENABLE_FULLSCREEN = '[FULLSCREEN] ENABLE_FULLSCREEN',
  EXIT_FULLSCREEN = '[FULLSCREEN] EXIT_FULLSCREEN',
}

export class EnableFullscreen {
  static readonly type = ActionsType.ENABLE_FULLSCREEN;
}

export class ExitFullscreen {
  static readonly type = ActionsType.EXIT_FULLSCREEN;
}
