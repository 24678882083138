import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {take} from 'rxjs/operators';

import {SidebarComponent} from '../components/sidebar/sidebar.component';

@Directive({
  selector: '[sgpSidebar]',
})
export class SidebarDirective implements OnChanges {
  private sidebarRef: ComponentRef<SidebarComponent> | null = null;

  @Input('sgpSidebar')
  isShown: boolean | null = false;

  @Output()
  close = new EventEmitter();

  private get sidebar(): SidebarComponent | undefined {
    return this.sidebarRef?.instance;
  }

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly template: TemplateRef<any>,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const isShownChange = changes['isShown'];

    if (!isShownChange) return;

    if (isShownChange.currentValue) {
      this.sidebarRef = this.createSidebarRef();
      if (!this.sidebar) return;

      this.sidebar.template = this.template;
      this.sidebar.clickOutside.pipe(take(1)).subscribe(_ => {
        this.close.emit();
      });
    } else {
      this.removeSidebar();
    }
  }

  private createSidebarRef(): ComponentRef<SidebarComponent> {
    const factory =
      this.componentFactoryResolver.resolveComponentFactory(SidebarComponent);

    return this.viewContainerRef.createComponent(factory);
  }

  private removeSidebar() {
    if (!this.sidebar) return;

    /** run sidebar close animation */
    this.sidebar.show = false;

    setTimeout(() => {
      this.viewContainerRef.clear();
      this.sidebarRef = null;
    }, 400);
  }
}
