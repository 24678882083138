import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import {tuiSlideInRight} from '@taiga-ui/core/animations';

import {fade} from './animations/fade.animation';

@Component({
  selector: 'sgp-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fade, tuiSlideInRight],
})
export class SidebarComponent {
  @Input() show: boolean | null = true;
  @Input() template: TemplateRef<any>;
  @Output() clickOutside = new EventEmitter();

  onClickOutside(event: MouseEvent) {
    event.stopImmediatePropagation();
    this.clickOutside.emit();
  }
}
