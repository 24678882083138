import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TuiScrollbarModule} from '@taiga-ui/core';

import {SidebarComponent} from './sidebar.component';

@NgModule({
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
  imports: [CommonModule, TuiScrollbarModule],
})
export class SgpSidebarModule {}
