import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

import {isNotNull} from './is-not-null.operator';
import {trim} from './trim-string.operator';

export function autocompleteFilter(debounceConfig = 300) {
  return (source$: Observable<string | null>) =>
    source$.pipe(
      debounceTime(debounceConfig),
      isNotNull<string>(),
      trim(),
      distinctUntilChanged(),
    );
}
