import {Directive, HostBinding, HostListener} from '@angular/core';

@Directive({
  selector: '[sgpHover]',
})
export class HoverDirective {
  _hover = false;

  @HostBinding('class._hover') get hover() {
    return this._hover;
  }

  @HostListener('mouseenter') mouseenter() {
    this._hover = true;
  }

  @HostListener('mouseleave') mouseleave() {
    this._hover = false;
  }
}
