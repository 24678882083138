import {Injectable} from '@angular/core';
import {LinkItem} from '@core/interfaces/link-item';
import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';

import {SetSelectedObject, SetSelectedProject} from './shared.actions';

export interface SharedProjectInfo {
  name: string;
  id: string;
}

export interface SharedObjectInfo {
  name: string;
  /* planId */
  id: string;
  objectId: string;
}

export interface SharedStateModel {
  livingProjectName: string;
  livingProjectId: string;
  livingObjectName: string;
  livingObjectId: string;
  rightObjectName: string;
  rightObjectId: string;
  breadCrumbs: LinkItem[];
  currentProject?: SharedProjectInfo | null;
  currentObject?: SharedObjectInfo | null;
}

const defaults: SharedStateModel = {
  livingProjectName: '',
  livingProjectId: '',
  livingObjectName: '',
  livingObjectId: '',
  rightObjectName: '',
  rightObjectId: '',
  breadCrumbs: [{name: 'Проекты', link: '/'}],
  currentProject: null,
  currentObject: null,
};

export const SHARED_STATE_NAME = 'shared';
export const SHARED_STATE_TOKEN = new StateToken<SharedStateModel>(SHARED_STATE_NAME);

@State({
  name: SHARED_STATE_TOKEN,
  defaults,
})
@Injectable()
export class SharedState {
  @Action(SetSelectedProject)
  setSelectedProject(
    ctx: StateContext<SharedStateModel>,
    action: SetSelectedProject,
  ): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      currentProject: action.project,
    });
  }

  @Action(SetSelectedObject)
  setSelectedObject(
    ctx: StateContext<SharedStateModel>,
    action: SetSelectedObject,
  ): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      currentObject: action.object,
    });
  }

  @Selector()
  static currentProjectInfo(state: SharedStateModel) {
    return state.currentProject;
  }

  @Selector()
  static currentObjectInfo(state: SharedStateModel) {
    return state.currentObject;
  }
}
