import {SharedObjectInfo, SharedProjectInfo} from '@shared/store/shared.state';

export enum ActionsType {
  SET_SELECTED_PROJECT = '[SHARED] Set selected project',
  SET_SELECTED_OBJECT = '[SHARED] Set selected living object',
}

export class SetSelectedProject {
  static readonly type = ActionsType.SET_SELECTED_PROJECT;
  constructor(public project: SharedProjectInfo | null) {}
}

export class SetSelectedObject {
  static readonly type = ActionsType.SET_SELECTED_OBJECT;
  constructor(public object: SharedObjectInfo | null) {}
}
