import {Injectable, Provider} from '@angular/core';
import {SidebarComponent} from '@app/modules/ui/sidebar/sidebar.component';
import {SidebarOptions} from '@app/modules/ui/sidebar/sidebar-options';
import {AbstractTuiDialogService, TUI_DIALOGS} from '@taiga-ui/cdk';
import {PolymorpheusComponent} from '@tinkoff/ng-polymorpheus';

@Injectable({
  providedIn: 'root',
})
export class SidebarService extends AbstractTuiDialogService<SidebarOptions<any>> {
  readonly defaultOptions: SidebarOptions<any> = {
    dismissible: true,
  };

  readonly component = new PolymorpheusComponent(SidebarComponent);
}

export const SIDEBAR_PROVIDER: Provider = {
  provide: TUI_DIALOGS,
  useExisting: SidebarService,
  multi: true,
};
